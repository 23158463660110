import { useMemo } from 'react';

import { authSelectors } from '@redux/auth';
import { useAppSelector } from '@redux/hooks';

import { isStatusLoading } from '@utils';

export const useAppIsLoading = () => {
  const { apiStatus } = useAppSelector(authSelectors.getProfileAPIData);

  const isLoading = useMemo(() => {
    return isStatusLoading(apiStatus);
  }, [apiStatus]);

  return isLoading;
};
