import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ForgotPasswordEmailSent } from './components/ForgotPasswordEmailSent';
import { ForgotPasswordForm } from './components/ForgotPasswordForm';

import { isStatusLoading } from '@utils';
import { authSelectors, forgotUserPassword, resetAuthSlice } from '@redux/auth';
import { AppRoutes } from '@constants';

export const ForgotPasswordPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { apiStatus, isEmailSent } = useAppSelector(
    authSelectors.getForgotPasswordAPIData,
  );
  const [username, setUsername] = useState('');

  const isLoading = isStatusLoading(apiStatus);

  const onChangeUsername = useCallback((value: string) => {
    setUsername(value);
  }, []);
  const onBack = useCallback(() => {
    navigate(`/${AppRoutes.SIGN_IN}`);
  }, []);
  const onSend = useCallback(() => {
    dispatch(forgotUserPassword(username));
  }, [username]);
  const onReset = useCallback(() => {
    dispatch(resetAuthSlice('forgotPassword'));
  }, []);
  const onResendEmail = useCallback(() => {
    dispatch(forgotUserPassword(username));
  }, [username]);

  return isEmailSent ? (
    <ForgotPasswordEmailSent
      username={username}
      onReset={onReset}
      onResendEmail={onResendEmail}
    />
  ) : (
    <ForgotPasswordForm
      username={username}
      isLoading={isLoading}
      onChangeUsername={onChangeUsername}
      onBack={onBack}
      onSend={onSend}
    />
  );
};
