import { useState } from 'react';

import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export const PasswordInput: React.FC<
  {
    name: string;
    label: string;
    value: any;
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    error?: boolean;
    helperText?: string;
  } & TextFieldProps
> = ({ name, label, value, onChange, onBlur, error, helperText, ...rest }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <TextField
      {...rest}
      name={name}
      label={label}
      variant="outlined"
      fullWidth
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      type={isVisible ? 'text' : 'password'}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setIsVisible(!isVisible)} edge="end">
              {isVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
