import { Box, CircularProgress } from '@mui/material';

export const PageLoader: React.FC = () => {
  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size={50} color="primary" />
    </Box>
  );
};
