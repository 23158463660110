import React from 'react';
import { Box } from '@mui/material';

export const BrandLogo: React.FC<{ width?: number }> = ({ width = 134 }) => {
  return (
    <Box
      component="img"
      src="https://ae-cp-static-files.s3.eu-central-1.amazonaws.com/client-dashboard/rocky-logo-vertical.png"
      alt="AgileEngine"
      sx={{
        width: `${width}px`,
      }}
    />
  );
};
