import { useMemo } from 'react';
import { testTaskFileUrl } from '@constants';

import { Box, Link, Typography } from '@mui/material';
import { PersonSignature, RowsDataLayout } from '@components';

import { ICandidateDetails } from '@types';
import { getTTColor } from '@utils';

export const CandidateDetailsTestTask: React.FC<{
  data: ICandidateDetails;
}> = ({ data }) => {
  const singleTestTaskDefault = data.testTasks?.[0];
  const mainProfileRowsData = useMemo(
    () =>
      data.testTasks?.length && singleTestTaskDefault
        ? [
            {
              label: 'Technology',
              value: singleTestTaskDefault.technology,
            },
            {
              label: 'Score',
              value: (
                <Typography
                  variant="body1"
                  color={getTTColor(singleTestTaskDefault)}
                >
                  {singleTestTaskDefault.score}%
                </Typography>
              ),
            },
            {
              label: 'Summary file',
              value: singleTestTaskDefault.summaryFileName ? (
                <Link
                  href={testTaskFileUrl(singleTestTaskDefault.summaryFileName)}
                  component={'a'}
                  target="_blank"
                  color="text.link"
                  sx={{ cursor: 'pointer' }}
                  fontFamily="inherit"
                >
                  {singleTestTaskDefault.summaryFileName} ↗
                </Link>
              ) : (
                'N/A'
              ),
            },
            {
              label: 'Feedback',
              value: (
                <>
                  <Typography whiteSpace="pre-line">
                    {singleTestTaskDefault.feedback}
                  </Typography>
                  <Box mt={3}>
                    <PersonSignature person={singleTestTaskDefault.reviewer} />
                  </Box>
                </>
              ),
            },
          ]
        : [],
    [data],
  );

  return <RowsDataLayout data={mainProfileRowsData} />;
};
