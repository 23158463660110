import { Box, Typography } from '@mui/material';
import avatarPlaceholder from '@assets/avatar-placeholder.svg';

import { IProfile } from '@types';

export const UserProfile: React.FC<{ profile: IProfile | null }> = ({
  profile,
}) => {
  if (!profile) return null;

  const { firstName, lastName, companyName } = profile;

  return (
    <Box display="flex" alignItems="center">
      <img
        src={avatarPlaceholder}
        style={{
          width: '40px',
          height: '40px',
        }}
      />
      <Box ml="0.5rem">
        <Typography variant="body1">{`${firstName} ${lastName}`}</Typography>
        <Typography variant="body2" color="text.secondary">
          {companyName}
        </Typography>
      </Box>
    </Box>
  );
};
