import { ClientFeedbackResolution, InterviewSlotOption } from '@constants';
import * as yup from 'yup';

export const validationSchema = yup.object({
  interviewSlots: yup
    .array()
    .of(
      yup.object({
        start: yup.string().nullable().required('Start time is required.'),
        end: yup.string().nullable().required('End time is required.'),
      }),
    )
    .nullable()
    .when(['resolution', 'interviewOption'], (formData, schema) => {
      const [resolution, interviewOption] = formData;

      if (
        resolution === ClientFeedbackResolution.CallRequested &&
        interviewOption !== InterviewSlotOption.MyCalendar
      )
        return schema
          .min(1, 'Please provide interview time slot or calendar link.')
          .required('Please provide interview time slot or calendar link.');

      return schema;
    }),
  clientCalendar: yup
    .string()
    .nullable()
    .max(1000, 'Max length is 1000 symbols.')
    .when(['resolution', 'interviewOption'], (formData, schema) => {
      const [resolution, interviewOption] = formData;

      if (
        resolution === ClientFeedbackResolution.CallRequested &&
        interviewOption == InterviewSlotOption.MyCalendar
      )
        return yup
          .string()
          .max(1000, 'Max length is 1000 symbols.')
          .required('Please provide interview time slot or calendar link.');

      return schema;
    }),
  details: yup
    .string()
    .max(5000, 'Max length is 5000 symbols.')
    .when('resolution', {
      is: (val: string) => val === ClientFeedbackResolution.Rejected,
      then: () => yup.string().required('Please enter some notes'),
      otherwise: () => yup.string().optional().nullable(),
    }),
});
