import { useEffect } from 'react';

import { useAppDispatch } from '@redux/hooks';
import { fetchProfile } from '@redux/auth';
import { useIsStandalonePage } from './useIsStandalonePage';

export const useFetchProfile = () => {
  const dispatch = useAppDispatch();
  const isStandalonePage = useIsStandalonePage();

  useEffect(() => {
    if (!isStandalonePage) {
      dispatch(fetchProfile());
    }
  }, [isStandalonePage]);
};
