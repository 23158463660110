import React from 'react';
import { Box, Fade } from '@mui/material';
import { fadeInDuration } from '../../../theme/variables';

interface IPageWrapperProps {
  children: React.ReactNode;
}

export const PageWrapper: React.FC<IPageWrapperProps> = ({ children }) => {
  return (
    <Fade in={true} timeout={fadeInDuration} mountOnEnter unmountOnExit>
      <Box>{children}</Box>
    </Fade>
  );
};
