import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  CandidateDetailsHeader,
  DateTimeRangePicker,
  FeedbackAndSchedulingModal,
  FeedbackAndSchedulingSuccessModal,
  InterviewSlots,
} from '@components';

import {
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  Stack,
  TextField,
} from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

import { isStatusLoading } from '@utils';
import { validationSchema } from './validationSchema';

import {
  cancelCandidateInterview,
  candidateDetailsSelectors,
  rescheduleCandidateInterview,
  setCandidateDetailsAction,
} from '@redux/candidateDetails';

import {
  CANDIDATE_INTERVIEW_CANCELLED,
  CandidateDetailsAction,
  CandidateStatus,
  InterviewSlotOption,
  SearchParams,
} from '@constants';
import { getUserOffset } from '@utils';
import { CandidateRescheduleCancelFormValues, DateTimeRange } from '@types';

export const ClientRescheduleCancelModal = () => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line
  const [_, setSearchParams] = useSearchParams();

  const { provideClientFeedbackAPIStatus, data } = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );
  const candidateDetailsAction = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsAction,
  );

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState<boolean>(false);

  const slots = data?.interview?.candidateSlots || [];

  const isLoading = isStatusLoading(provideClientFeedbackAPIStatus);

  const formik = useFormik<CandidateRescheduleCancelFormValues>({
    initialValues: {
      interviewSlots: null,
      interviewOption: null,
      clientCalendar: null,
      details: null,
      clientTimezone: data?.interview?.clientTimezone || getUserOffset(),
      isCancelled: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (data?.id && values.isCancelled) {
        const res = await dispatch(
          cancelCandidateInterview({
            candidateId: data.id,
            details: values.details,
          }),
        );
        if (cancelCandidateInterview.fulfilled.match(res)) {
          handleModalClose();
          setSuccessModalOpen(true);
        }
      } else if (data?.id) {
        const res = await dispatch(
          rescheduleCandidateInterview({
            candidateId: data.id,
            details: values.details,
            clientCalendar: values.clientCalendar,
            interviewSlots: values.interviewSlots,
          }),
        );
        if (rescheduleCandidateInterview.fulfilled.match(res)) {
          handleModalClose();
          setSuccessModalOpen(true);
        }
      }
    },
  });

  useEffect(() => {
    if (candidateDetailsAction) {
      if (
        [
          CandidateDetailsAction.RESCHEDULE_INTERVIEW,
          CandidateDetailsAction.CANCEL_INTERVIEW,
        ].includes(candidateDetailsAction)
      ) {
        setModalOpen(true);
      }
    } else {
      formik.resetForm();
      setModalOpen(false);
    }
  }, [candidateDetailsAction]);

  const handleModalClose = () => {
    dispatch(setCandidateDetailsAction(null));
    setSearchParams(
      (_searchParams) => {
        _searchParams.delete(SearchParams.ACTION);
        return _searchParams;
      },
      { replace: true },
    );
  };

  const handleCancelInterviewClick = () => {
    formik.setValues({
      ...formik.values,
      isCancelled: true,
      interviewSlots: null,
      interviewOption: null,
      clientCalendar: null,
    });
  };

  const handleSlotChange = (newSlot: DateTimeRange | null) => {
    formik.setValues({
      ...formik.values,
      isCancelled: false,
      interviewSlots: newSlot ? [newSlot] : null,
      interviewOption: null,
      clientCalendar: null,
    });
  };

  const handleOptionChange = (option: InterviewSlotOption) => {
    formik.setValues({
      ...formik.values,
      interviewOption: option,
      interviewSlots: null,
      clientCalendar: null,
      isCancelled: false,
    });
  };

  const interviewSlotsError =
    ((formik.submitCount || formik.touched.interviewSlots) &&
      formik.errors.interviewSlots) ||
    '';

  const clientCalendarError =
    ((formik.submitCount || formik.touched.clientCalendar) &&
      formik.errors.clientCalendar) ||
    '';

  if (!data) return null;

  return (
    <>
      <FeedbackAndSchedulingModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title={
          <CandidateDetailsHeader
            data={data}
            headerText={`Interviewing ${data?.profile?.firstName} ${data?.profile?.lastName}`}
          />
        }
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        submitLabel="Send"
        additionalAction={
          <Button
            variant="contained"
            color="secondary"
            onClick={handleModalClose}
          >
            Close
          </Button>
        }
      >
        <Stack gap="1.5rem">
          <InterviewSlots
            slots={slots}
            selectedSlot={formik.values.interviewSlots?.[0] || null}
            clientTimezone={formik.values.clientTimezone}
            interviewOption={formik.values.interviewOption}
            error={(interviewSlotsError || clientCalendarError) as string}
            candidateReadyAnytime={data.interview?.candidateReadyAnytime}
            setSelectedSlot={handleSlotChange}
            setInterviewOption={handleOptionChange}
          >
            {data.status !== CandidateStatus.CallCancelled && (
              <FormControlLabel
                key={CANDIDATE_INTERVIEW_CANCELLED}
                value={CANDIDATE_INTERVIEW_CANCELLED}
                onClick={handleCancelInterviewClick}
                control={<Radio checked={formik.values.isCancelled} />}
                label={'Cancel interview'}
              />
            )}
            {formik.values.interviewOption ===
              InterviewSlotOption.MyCalendar && (
              <FormControlLabel
                key={`slot_${InterviewSlotOption.MyCalendar}`}
                control={<Radio checked={true} />}
                label={
                  <TextField
                    name="my-calendly"
                    label="My calendar link"
                    placeholder="My calendar link"
                    variant="outlined"
                    fullWidth
                    value={formik.values.clientCalendar || ''}
                    onChange={(e) =>
                      formik.setFieldValue(
                        'clientCalendar',
                        e.target.value || null,
                      )
                    }
                    onBlur={() => formik.setFieldTouched('clientCalendar')}
                  />
                }
              />
            )}
            {formik.values.interviewOption === InterviewSlotOption.Date && (
              <FormControlLabel
                key={`slot_${InterviewSlotOption.Date}`}
                control={<Radio checked={true} />}
                label={
                  <Stack direction="row" gap="1rem">
                    <DateTimeRangePicker
                      timeZone={formik.values.clientTimezone}
                      date={formik.values.interviewSlots?.[0]}
                      handleChange={(date) =>
                        formik.setFieldValue('interviewSlots', [date])
                      }
                      maxWidth={'20rem'}
                    />
                    <IconButton
                      onClick={() => {
                        formik.setValues({
                          ...formik.values,
                          interviewSlots: [],
                          interviewOption: null,
                        });
                      }}
                    >
                      <DeleteOutlineRoundedIcon />
                    </IconButton>
                  </Stack>
                }
              />
            )}
          </InterviewSlots>
          <TextField
            fullWidth
            multiline
            minRows={3}
            name="details"
            label="Notes"
            placeholder={
              'Please add any suggestions you have about this candidate or the interview.'
            }
            variant="outlined"
            value={formik.values.details || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.errors.details as string}
            error={formik.touched.details && !!formik.errors.details}
          />
        </Stack>
      </FeedbackAndSchedulingModal>
      <FeedbackAndSchedulingSuccessModal
        data={data}
        isOpen={isSuccessModalOpen}
        isPassed={data.status === CandidateStatus.CallRequested}
        label={
          data.status === CandidateStatus.CallRequested
            ? "Thanks, we'll set up an interview"
            : 'Thank you for your response!'
        }
        subLabel={'Our Recruiter may get back to you to clarify details.'}
        handleClose={() => setSuccessModalOpen(false)}
      />
    </>
  );
};
