/** @jsxImportSource @emotion/react */
import React, {
  ChangeEvent,
  DragEvent,
  KeyboardEvent,
  MouseEvent,
  MutableRefObject,
  useRef,
  useState,
  useEffect,
} from 'react';
import cl from 'classnames';
import uniqBy from 'lodash/uniqBy';

import {
  Box,
  Button,
  IconButton,
  ButtonBaseActions,
  Typography,
} from '@mui/material';
import { useInputFileStyles } from './FileInput.styles';
import { AttachFile, Close } from '@mui/icons-material';

import {
  INVALID_FORMAT_ERROR_MESSAGE,
  LARGE_FILE_ERROR_MESSAGE,
  ACCEPTED_EXTENSIONS,
  MAX_FILE_SIZE,
} from '@constants';
import { isTruthy } from '@utils';
import { uniq } from 'lodash';

interface IProps {
  hint?: string;
  name?: string;
  value?: string[] | null;
  error?: Record<number, string>;
  onChange?: ({
    files,
    fileNames,
  }: {
    files: File[];
    fileNames: string[];
  }) => void;
  acceptedExtensions?: string[];
  multiple?: boolean;
}

export const FileInput: React.FC<IProps> = ({
  hint,
  name,
  value,
  error,
  onChange,
  acceptedExtensions = ACCEPTED_EXTENSIONS,
  multiple,
}): React.ReactElement => {
  const styles = useInputFileStyles();

  const [fileNames, setFileNames] = useState<string[]>(
    () => value?.filter(isTruthy) || [],
  );
  const [files, setFiles] = useState<File[] | []>([]);
  const [errors, setErrors] = useState<Record<number, string>>(error ?? {});

  const [isDragAndDrop, setIsDragAndDrop] = useState(false);
  const fileInputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const fileWrapperRef = useRef() as MutableRefObject<HTMLLabelElement>;
  const buttonActionRef = useRef() as MutableRefObject<ButtonBaseActions>;

  useEffect(() => {
    fileNames.forEach((fileName, index) => {
      const file = files.find((e) => e.name === fileName);

      if (file) {
        validateFileInput(file, index);
      }
    });

    if (onChange) {
      onChange({ files, fileNames });
    }
  }, [files, fileNames]);

  const validateFileInput = (file?: File, index = 0) => {
    if (!file) return;

    const { name: fileName, size } = file;

    const splittingExtension = fileName.split('.');
    const extension = '.' + splittingExtension[splittingExtension.length - 1];
    const isAllowed = acceptedExtensions.find(
      (ext) => ext === extension.toLowerCase(),
    );

    if (size > MAX_FILE_SIZE) {
      setErrors((prev) => ({ ...prev, [index]: LARGE_FILE_ERROR_MESSAGE }));
    } else if (!isAllowed) {
      setErrors((prev) => ({
        ...prev,
        [index]: INVALID_FORMAT_ERROR_MESSAGE(acceptedExtensions.join(', ')),
      }));
    } else {
      setErrors((prev) => ({ ...prev, [index]: '' }));
    }

    setIsDragAndDrop(false);
  };

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const rawFiles = Array.from(e.target.files);
    const rawFileNames = rawFiles.map((e) => e.name);
    setFiles((prev) => uniqBy([...prev, ...rawFiles], 'name'));
    setFileNames((prev) => uniq([...prev, ...rawFileNames]));
  };

  const onDropFile = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.dataTransfer.files) {
      return;
    }

    const rawFiles = Array.from(e.dataTransfer.files);
    const rawFileNames = rawFiles.map((e) => e.name);
    setFiles((prev) => uniqBy([...prev, ...rawFiles], 'name'));
    setFileNames((prev) => uniq([...prev, ...rawFileNames]));
  };

  const clearInputFile = (index = 0, event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const nextFileNames = fileNames.filter((_, idx) => idx !== index);
    const nextFiles = files.filter((file) => nextFileNames.includes(file.name));

    setFileNames(nextFileNames);
    setFiles(nextFiles);

    setErrors((prev) => ({ ...prev, [index]: '' }));
  };

  const onKeyUp = (e: KeyboardEvent) => {
    e.stopPropagation();
    if (e.code === 'Enter' || e.code === 'Space') {
      fileInputRef.current?.click();
    }
  };

  return (
    <Box css={styles}>
      <Box position="relative">
        <Button
          ref={fileWrapperRef}
          action={buttonActionRef}
          className={cl('file-input', { 'drag-and-drop': isDragAndDrop })}
          variant="outlined"
          component="label"
          endIcon={
            fileNames?.length && !multiple ? (
              <IconButton onClick={(e) => clearInputFile(0, e)}>
                <Close fontSize="small" />
              </IconButton>
            ) : null
          }
          onDragOver={(e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragAndDrop(true);
          }}
          onDragLeave={(e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragAndDrop(false);
          }}
          onDrop={onDropFile}
          sx={{ letterSpacing: 0, fontSize: '1rem' }}
        >
          <div className={'file-input-labels'}>
            {!multiple && fileNames?.length ? (
              <span className="file-input-labels--filename">
                {fileNames.toString()}
              </span>
            ) : (
              <>
                <Typography color="text.link">
                  Drag and drop or{' '}
                  <Box
                    onKeyUp={onKeyUp}
                    component="span"
                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    browse
                  </Box>{' '}
                  to upload screenshots or files
                </Typography>
                {hint && <div className="file-input-labels--hint">{hint}</div>}
              </>
            )}
          </div>
          <input
            type="file"
            accept={acceptedExtensions.toString()}
            hidden
            name={name}
            onChange={onChangeFile}
            multiple={multiple}
            ref={fileInputRef}
          />
        </Button>
      </Box>

      {multiple && !!fileNames.length && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          {fileNames?.map((fileName, index) => (
            <Box key={fileName}>
              <Button
                fullWidth
                variant="contained"
                component="div"
                color="info"
                startIcon={<AttachFile className="file-input-icon-attach" />}
                endIcon={
                  fileName ? (
                    <IconButton onClick={(e) => clearInputFile(index, e)}>
                      <Close fontSize="small" sx={{ color: 'white' }} />
                    </IconButton>
                  ) : null
                }
              >
                <Box
                  sx={{
                    width: '100%',
                    textAlign: 'left',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                  title={fileName}
                >
                  {fileName}
                </Box>
              </Button>
              {errors[index] && (
                <Box className="file-input-error" mt={0.5}>
                  {errors[index]}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      )}

      {!multiple &&
        errors &&
        Object.values(errors).map((item, index) => (
          <Box key={index} className="file-input-error" mt={0.5}>
            {item}
          </Box>
        ))}
    </Box>
  );
};

export default FileInput;
