import { useState } from 'react';
import axios from 'axios';
import { useAppDispatch } from '@redux/hooks';
import * as yup from 'yup';
import { useFormik } from 'formik';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { FileInput } from 'components/common';
import CloseIcon from '@mui/icons-material/Close';

import { setSnackbar } from '@redux/snackbar';
import { REPORT_ISSUE_TYPE, SnackbarType } from '@constants';

export const ReportIssue: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik<{
    issueType: string;
    issueDescription: string;
    attachments: File[];
  }>({
    initialValues: {
      issueType: 'General feedback',
      issueDescription: '',
      attachments: [],
    },
    validationSchema: yup.object({
      issueType: yup.string().required('Issue type is required'),
      issueDescription: yup
        .string()
        .max(5000)
        .required('Issue description is required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);

      const form = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        if (value) {
          if (Array.isArray(value)) {
            for (const file of value) {
              form.append(key, file);
            }
          } else {
            form.append(key, value);
          }
        }
      });

      try {
        await axios.post(`/report-issue`, form, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        dispatch(
          setSnackbar({
            type: SnackbarType.Info,
            message: 'Issue has been reported.',
          }),
        );
        resetForm();
        onClose();
      } catch (e: any) {
        dispatch(
          setSnackbar({
            type: SnackbarType.Error,
            message: `Issue hasn't been reported due to ${e.message}.`,
          }),
        );
      } finally {
        setIsLoading(false);
      }
    },
    enableReinitialize: true,
  });

  const issueDescriptionError =
    formik.touched.issueDescription && formik.errors.issueDescription;

  return (
    <Dialog
      open={isOpen}
      fullWidth
      PaperProps={{
        style: {
          maxWidth: '37.5rem',
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h2">Report an issue</Typography>
          <CloseIcon
            onClick={() => onClose()}
            sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
          />
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{ padding: '16px 24px', paddingTop: '16px !important' }}
      >
        <Box display="flex" flexDirection="column" gap={3}>
          <TextField
            select
            fullWidth
            required
            name="issueType"
            value={formik.values.issueType}
            onChange={formik.handleChange}
            error={formik.touched.issueType && !!formik.errors.issueType}
            helperText={formik.touched.issueType && formik.errors.issueType}
          >
            {Object.values(REPORT_ISSUE_TYPE).map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="issueDescription"
            label="Please describe any questions or ideas you have."
            value={formik.values.issueDescription}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            required
            multiline
            minRows={5}
            error={!!issueDescriptionError}
            helperText={
              <>
                {issueDescriptionError ? issueDescriptionError : undefined}
                <Typography color="text.secondary">
                  Someone from AgileEngine may email you for further
                  clarification.
                </Typography>
              </>
            }
          />
          <FileInput
            hint="Max 10 files, 5 MB each"
            name="attachments"
            multiple
            onChange={({ files }) => formik.setFieldValue('attachments', files)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="end" gap={1}>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Close
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              formik.handleSubmit();
            }}
            disabled={!formik.dirty || !formik.isValid || isLoading}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
