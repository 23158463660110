import { RootState } from '@redux/hooks';
import { snackbarStoreKey } from './snackbar.const';

const selectSnackbarState = (state: RootState) => {
  return state[snackbarStoreKey];
};

const getSanckbarData = (state: RootState) => {
  return selectSnackbarState(state);
};

export const snackbarSelectors = {
  getSanckbarData,
};
