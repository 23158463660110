import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { useParams, useSearchParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import {
  CandidateDetailsHeader,
  CandidaterDetailsActionButton,
  CandidateDetailsContent,
  CandidateDetailsPageLayout,
  PageLoader,
  ClientFeebackModal,
  ClientRescheduleCancelModal,
  ClientInterviewFeedbackModal,
} from '@components';
import {
  candidateDetailsSelectors,
  clearCandidateDetails,
  fetchCandidateDetails,
  setCandidateDetailsAction,
} from '@redux/candidateDetails';
import { CandidateDetailsAction, SearchParams } from '@constants';
import { isStatusLoading } from '@utils';

export const CandidateDetailsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { candidateId } = useParams();
  const [searchParams] = useSearchParams();

  const { apiStatus, data } = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );

  useEffect(() => {
    if (!candidateId) return;

    dispatch(fetchCandidateDetails(candidateId));

    const action = searchParams.get(
      SearchParams.ACTION,
    ) as CandidateDetailsAction;

    if (Object.values(CandidateDetailsAction).includes(action)) {
      dispatch(setCandidateDetailsAction(action));
    }

    return () => {
      dispatch(clearCandidateDetails());
    };
  }, [candidateId]);

  if (isStatusLoading(apiStatus)) {
    return <PageLoader />;
  }

  if (!data) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
      >
        <Typography variant="h1">Candidate not found</Typography>
      </Box>
    );
  }

  return (
    <>
      <CandidateDetailsPageLayout
        headerComponent={<CandidateDetailsHeader data={data} />}
        contentComponent={<CandidateDetailsContent data={data} />}
        footerComponent={<CandidaterDetailsActionButton data={data} />}
      />
      <ClientFeebackModal />
      <ClientRescheduleCancelModal />
      <ClientInterviewFeedbackModal />
    </>
  );
};
