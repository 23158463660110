import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import { Alert, Snackbar } from '@mui/material';

import { eraseSnackbar, snackbarSelectors } from '@redux/snackbar';

export const GlobalSnackbar = () => {
  const dispatch = useAppDispatch();

  const snackbarData = useAppSelector(snackbarSelectors.getSanckbarData);

  const onCloseSnackbar = useCallback(() => dispatch(eraseSnackbar()), []);

  return (
    <Snackbar
      open={!!snackbarData.message}
      onClose={onCloseSnackbar}
      autoHideDuration={6000}
    >
      <Alert
        onClose={onCloseSnackbar}
        severity={snackbarData.type}
        sx={{ width: '100%' }}
      >
        {snackbarData.message}
      </Alert>
    </Snackbar>
  );
};
