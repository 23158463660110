import { RootState } from '@redux/hooks';
import { authStoreKey } from './auth.const';

const selectAuthState = (state: RootState) => {
  return state[authStoreKey];
};

const getLoginAPIData = (state: RootState) => {
  return selectAuthState(state).login;
};
const getForgotPasswordAPIData = (state: RootState) => {
  return selectAuthState(state).forgotPassword;
};

const getResetPasswordAPIData = (state: RootState) => {
  return selectAuthState(state).resetPassword;
};

const getProfileAPIData = (state: RootState) => {
  return selectAuthState(state).profile;
};

const getShowWelcomeScreen = (state: RootState) => {
  return selectAuthState(state).showWelcomeScreen;
};

export const authSelectors = {
  getLoginAPIData,
  getForgotPasswordAPIData,
  getResetPasswordAPIData,
  getProfileAPIData,
  getShowWelcomeScreen,
};
