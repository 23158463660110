import React from 'react';

import { ClientResolution } from './component/ClientResolution';
import { CandidateDetailsFeedbackNotes } from '@components';

import { Stack } from '@mui/material';

import { ICandidateDetails } from '@types';

export const CandidateDetailsClientFeeback: React.FC<{
  data: ICandidateDetails;
}> = ({ data }) => {
  return (
    <Stack>
      <ClientResolution data={data} />
      <CandidateDetailsFeedbackNotes data={data} />
    </Stack>
  );
};
