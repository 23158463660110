export const INVALID_FORMAT_ERROR_MESSAGE = (extensions: string) =>
  `Please attach your file in the following format: ${extensions}`;
export const EMPTY_FILE_ERROR_MESSAGE =
  'Looks like your file has no data, please attach the correct one';
export const LARGE_FILE_ERROR_MESSAGE = 'Please attach file, 5 MB each';
export const ACCEPTED_EXTENSIONS: string[] = [
  '.jpeg',
  '.jpg',
  '.png',
  '.gif',
  '.webp',
  '.mov',
];
export const MAX_FILE_SIZE = 5 * 1048576 - 1; // 4.(9) MB
