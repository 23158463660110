import { RootState } from '@redux/hooks';
import { candidateDetailsStoreKey } from './candidateDetails.const';

const selectCandidateDetailsState = (state: RootState) => {
  return state[candidateDetailsStoreKey];
};

const getCandidateDetailsAction = (state: RootState) => {
  return selectCandidateDetailsState(state).candidateDetailsAction;
};

const getCandidateDetailsData = (state: RootState) => {
  return selectCandidateDetailsState(state);
};

export const candidateDetailsSelectors = {
  getCandidateDetailsData,
  getCandidateDetailsAction,
};
