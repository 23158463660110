import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

import InterVariableFont from '@assets/fonts/Inter.woff2';

export default <Components<Theme>>{
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'Inter var';
        font-style: normal;
        font-display: swap;
        font-weight: 100 900;
        src: url(${InterVariableFont}) format('woff2');
        font-named-instance: 'Regular',
      }
      html {
        -webkit-font-smoothing: auto;
      }
      body {
        background: #F6F8FC;
      }
    `,
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.10);',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        ...(ownerState.variant === 'outlined' &&
          ownerState.color === 'primary' && {
            color: theme.palette.button.outlined.primary.text,
            borderColor: theme.palette.button.outlined.primary.border,
            background: theme.palette.button.outlined.primary.background,
            textTransform: 'none',
            '&:hover': {
              color: theme.palette.button.outlined.primary.textHover,
              borderColor: theme.palette.button.outlined.primary.borderHover,
              background: theme.palette.button.outlined.primary.backgroundHover,
            },
          }),
        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'primary' && {
            color: theme.palette.button.contained.primary.text,
            borderColor: theme.palette.button.contained.primary.border,
            background: theme.palette.button.contained.primary.background,
            textTransform: 'none',
            '&:hover': {
              color: theme.palette.button.contained.primary.textHover,
              borderColor: theme.palette.button.contained.primary.borderHover,
              background:
                theme.palette.button.contained.primary.backgroundHover,
            },
          }),
        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'secondary' && {
            color: theme.palette.button.contained.secondary.text,
            borderColor: theme.palette.button.contained.secondary.border,
            background: theme.palette.button.contained.secondary.background,
            textTransform: 'none',
            '&:hover': {
              color: theme.palette.button.contained.secondary.textHover,
              borderColor: theme.palette.button.contained.secondary.borderHover,
              background:
                theme.palette.button.contained.secondary.backgroundHover,
            },
          }),
      }),
    },
  },
  MuiDrawer: {
    styleOverrides: {
      root: {
        '&.MuiDrawer-root .MuiBackdrop-root': {
          background: 'transparent',
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.Mui-checked': {
          color: theme.palette.text.link,
        },
        '&:hover': {
          backgroundColor: theme.palette.text.linkLight,
        },
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.Mui-disabled': {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.inputDisabled,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.text.link,
          },
        },
      }),
      input: ({ theme }) => ({
        '&.Mui-disabled': {
          textFillColor: theme.palette.text.primary,
        },
      }),
    },
  },
  MuiListSubheader: {
    styleOverrides: {
      root: {
        color: '#00000099',
        fontWeight: '400',
        fontSize: '13px',
        marginBottom: '-8px',
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      root: {
        color: '#000000DE',
        fontWeight: '500',
        fontSize: '13px',
      },
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        ...(ownerState.color === 'primary' && {
          color: theme.palette.brand.accent,
        }),
      }),
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.Mui-checked': {
          color: theme.palette.text.link,
        },
      }),
    },
  },
};
