import React from 'react';

import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { TextButton } from '@components';

import { InterviewSlotOption } from '@constants';
import { geDateTimeInTimezone } from '@utils';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { DateTimeRange } from '@types';

interface IInterviewSlotsProps {
  slots: DateTimeRange[];
  selectedSlot: DateTimeRange | null;
  candidateReadyAnytime?: boolean;
  clientTimezone: string;
  interviewOption: InterviewSlotOption | null;
  setInterviewOption: (newOption: InterviewSlotOption) => void;
  setSelectedSlot: (newSlot: DateTimeRange | null) => void;
  error?: string;
  children?: React.ReactNode;
}

export const InterviewSlots: React.FC<IInterviewSlotsProps> = ({
  slots,
  selectedSlot,
  candidateReadyAnytime,
  clientTimezone,
  error,
  interviewOption,
  setSelectedSlot,
  setInterviewOption,
  children,
}) => {
  const handleSlotChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSlot(e.target.value ? JSON.parse(e.target.value) : null);
  };

  return (
    <Box>
      <Typography variant="body2" color="text.primary" mb={1}>
        Interview slots{' '}
        {clientTimezone && (
          <Box component="span" color="text.secondary" fontSize="90%">
            UTC {clientTimezone}
          </Box>
        )}
      </Typography>
      <Stack
        padding="1.5rem"
        bgcolor="background.backgroundPrimary"
        borderRadius="0.5rem"
        gap={2}
      >
        {candidateReadyAnytime && (
          <Typography variant="body2">
            Candidate is available at any time. Please specify your preferred
            meeting slots.
          </Typography>
        )}
        <Stack>
          <FormControl>
            <RadioGroup
              value={JSON.stringify(selectedSlot)}
              onChange={handleSlotChange}
            >
              {slots.map((slot, index) => (
                <FormControlLabel
                  key={`slot_${index}`}
                  value={JSON.stringify(slot)}
                  control={<Radio checked={isEqual(slot, selectedSlot)} />}
                  label={`${geDateTimeInTimezone(
                    slot.start,
                    clientTimezone,
                  ).format('ddd, MMM DD, hh:mm a')} - ${geDateTimeInTimezone(
                    slot.end ? dayjs(slot.end).toISOString() : null,
                    clientTimezone,
                  ).format('hh:mm a')}`}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {children}
        </Stack>
        <Box display="flex" gap={4}>
          {interviewOption !== InterviewSlotOption.Date && (
            <TextButton
              variant="primary"
              onClick={() => setInterviewOption(InterviewSlotOption.Date)}
            >
              Add my slot
            </TextButton>
          )}
          {interviewOption !== InterviewSlotOption.MyCalendar && (
            <TextButton
              variant="primary"
              onClick={() => setInterviewOption(InterviewSlotOption.MyCalendar)}
            >
              Share my calendar
            </TextButton>
          )}
        </Box>
      </Stack>
      {error ? (
        <Typography
          variant="body1"
          color="text.danger"
          margin="0.5rem 0 0 1.5rem"
        >
          {error}
        </Typography>
      ) : null}
    </Box>
  );
};
