import { useState } from 'react';
import { PersonSignature } from '@components';
import {
  Box,
  Typography,
  Button,
  Collapse,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { ICandidateDetails } from '@types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export const CandidateDetailsFeeback: React.FC<{ data: ICandidateDetails }> = ({
  data,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState(false);

  if (!data.feedback) return null;

  const wordLimit = isMobile ? 20 : 50;
  const words = data.feedback.feedback?.split(' ');

  const isLongText = words && words.length > wordLimit;
  const displayedText =
    isLongText && !expanded
      ? words.slice(0, wordLimit).join(' ')
      : data.feedback.feedback;

  const handleReadMoreClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Box position={'relative'}>
        <Typography whiteSpace="pre-line">{displayedText}</Typography>

        {isLongText && !expanded && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '50%',
              background:
                'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(255, 255, 255 , 255))',
            }}
          />
        )}

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Typography whiteSpace="pre-line">
            {data.feedback.feedback}
          </Typography>
          <Box mt={3}>
            <PersonSignature person={data.feedback?.reviewer} />
          </Box>
        </Collapse>

        {isLongText && (
          <Button
            onClick={handleReadMoreClick}
            variant="text"
            color="info"
            size="small"
            sx={{
              justifyContent: 'end',
              color: 'text.link',
              fontWeight: 400,
              textTransform: 'unset',
              paddingY: '5px',
              paddingX: '8px',
              background: '#E6F2FC',
              marginTop: '12px',
            }}
            endIcon={expanded ? <ExpandLessIcon /> : <KeyboardArrowDownIcon />}
          >
            {expanded ? 'Show less' : 'Read more'}
          </Button>
        )}
      </Box>
    </>
  );
};
