import { ReactNode, useEffect, useRef, useState } from 'react';

import { Box, Breadcrumbs, Link, Stack } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { AppRoutes } from '@constants';

export const CandidateDetailsPageLayout: React.FC<{
  headerComponent: ReactNode;
  contentComponent: ReactNode;
  footerComponent: ReactNode;
}> = ({ headerComponent, contentComponent, footerComponent }) => {
  const [height, setHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setHeight(ref.current?.clientHeight ?? 0);
  });

  return (
    <Stack position="relative">
      {/* Breadcrumb Section */}
      <Box
        padding={{
          xs: '0.5rem 0.5rem 0 0.5rem',
          md: '1.5rem 1.5rem 0 1.5rem',
        }}
        sx={{
          '@media (min-width:1180px)': {
            position: 'absolute',
          },
        }}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center', gap: '0.625rem' }}
            color="text.link"
            href={`/${AppRoutes.CANDIDATES}`}
            fontSize="0.875rem"
          >
            <ArrowBackRoundedIcon sx={{ fontSize: '1rem' }} />
            Candidates
          </Link>
        </Breadcrumbs>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        maxWidth="42.25rem"
        width="100%"
        gap="0.75rem"
        paddingTop="0.75rem"
        marginX="auto"
        paddingBottom={`${height + 28}px`}
      >
        {headerComponent}

        <Box width="100%">{contentComponent}</Box>

        <Box
          ref={ref}
          sx={{
            position: 'fixed',
            bottom: 0,
            zIndex: 9,
            maxWidth: '42.25rem',
            width: '100%',
          }}
        >
          {footerComponent}
        </Box>
      </Box>
    </Stack>
  );
};
