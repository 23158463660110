import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ApiStatus } from '@constants';
import { authStoreKey } from './auth.const';
import {
  fetchProfile,
  forgotUserPassword,
  loginUser,
  logoutUser,
  refreshUserToken,
  resetUserPassword,
} from './auth.thunk';

import { IAuthSliceState } from './auth.types';

const initialState: IAuthSliceState = {
  login: { apiStatus: ApiStatus.IDLE },
  forgotPassword: { apiStatus: ApiStatus.IDLE, isEmailSent: false },
  resetPassword: { apiStatus: ApiStatus.IDLE },
  refreshToken: { apiStatus: ApiStatus.IDLE },
  profile: {
    apiStatus: ApiStatus.IDLE,
    data: null,
  },
  showWelcomeScreen: false,
};

const authSlice = createSlice({
  name: authStoreKey,
  initialState,
  reducers: {
    resetAuthSlice: (state, action: PayloadAction<keyof IAuthSliceState>) => {
      const sliceName = action.payload;
      // @ts-expect-error: No error
      state[sliceName] = initialState[sliceName];
    },
    setShowWelcomeScreen: (state, action: PayloadAction<boolean>) => {
      state.showWelcomeScreen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Login User
      .addCase(loginUser.pending, (state) => {
        state.login.apiStatus = ApiStatus.LOADING;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.login.apiStatus = ApiStatus.COMPLETE;
        state.profile = {
          apiStatus: ApiStatus.COMPLETE,
          data: action.payload!,
        };
        state.showWelcomeScreen = true;
      })
      .addCase(loginUser.rejected, (state) => {
        state.login.apiStatus = ApiStatus.FAILED;
      })
      // Logout User
      .addCase(logoutUser.fulfilled, () => initialState)
      // Forgot Password
      .addCase(forgotUserPassword.pending, (state) => {
        state.forgotPassword.apiStatus = ApiStatus.LOADING;
      })
      .addCase(forgotUserPassword.fulfilled, (state) => {
        state.forgotPassword.apiStatus = ApiStatus.COMPLETE;
        state.forgotPassword.isEmailSent = true;
      })
      .addCase(forgotUserPassword.rejected, (state) => {
        state.forgotPassword.apiStatus = ApiStatus.FAILED;
      })
      // Reset Password
      .addCase(resetUserPassword.pending, (state) => {
        state.resetPassword.apiStatus = ApiStatus.LOADING;
      })
      .addCase(resetUserPassword.fulfilled, (state, action) => {
        state.resetPassword.apiStatus = ApiStatus.COMPLETE;
        state.profile = {
          apiStatus: ApiStatus.COMPLETE,
          data: action.payload!,
        };
        state.showWelcomeScreen = true;
      })
      .addCase(resetUserPassword.rejected, (state) => {
        state.resetPassword.apiStatus = ApiStatus.FAILED;
      })
      // Refresh Token
      .addCase(refreshUserToken.pending, (state) => {
        state.refreshToken.apiStatus = ApiStatus.LOADING;
      })
      .addCase(refreshUserToken.fulfilled, (state) => {
        state.refreshToken.apiStatus = ApiStatus.COMPLETE;
      })
      .addCase(refreshUserToken.rejected, (state) => {
        state.refreshToken.apiStatus = ApiStatus.FAILED;
      })
      // Get Profile
      .addCase(fetchProfile.pending, (state) => {
        state.profile.apiStatus = ApiStatus.LOADING;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.profile.apiStatus = ApiStatus.COMPLETE;
        state.profile.data = action.payload;
      })
      .addCase(fetchProfile.rejected, (state) => {
        state.profile.apiStatus = ApiStatus.FAILED;
      });
  },
});

export const { resetAuthSlice, setShowWelcomeScreen } = authSlice.actions;
export const authSliceReducer = authSlice.reducer;
