import { RootState } from '@redux/hooks';
import { positionsStoreKey } from './positions.const';

const selectPositonsState = (state: RootState) => {
  return state[positionsStoreKey];
};

const getPositionsData = (state: RootState) => {
  return selectPositonsState(state);
};

export const posiitonsSelectors = {
  getPositionsData,
};
