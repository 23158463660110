import React from 'react';
import {
  Box,
  CircularProgress,
  Fade,
  Slide,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { BottomGradientBar, BrandLogo } from 'components/common';
import { fadeInDuration, slideInDuration } from '@theme/variables';

export const WelcomeScreen: React.FC = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Stack
      ref={containerRef}
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100vh"
      data-testid="is-loading"
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          backgroundColor: theme.palette.common.white,
        },
      })}
    >
      <Box position="absolute" top={0} left={0} m={{ xs: '1rem', md: '2rem' }}>
        <BrandLogo />
      </Box>
      <Box flex={1} mb="auto" />
      <Stack alignItems="center" flex={1}>
        <Slide
          direction="down"
          in
          unmountOnExit
          timeout={slideInDuration}
          style={{ transitionDelay: '500ms' }}
          container={containerRef.current}
        >
          <Fade in timeout={fadeInDuration}>
            <Box textAlign="center">
              <Typography variant="h1">Welcome to Rocky!</Typography>
              <Typography variant="h5">
                Your personal assistant for managing teams, candidate pipelines,
                and more
              </Typography>
            </Box>
          </Fade>
        </Slide>
        <Box py="2rem">
          <CircularProgress
            sx={{ color: (theme) => theme.palette.brand.accent }}
          />
        </Box>
      </Stack>
      <Slide
        direction="up"
        in
        unmountOnExit
        timeout={slideInDuration}
        style={{ transitionDelay: '500ms' }}
        container={containerRef.current}
      >
        <Fade in timeout={fadeInDuration}>
          <Box
            component="img"
            src="https://ae-cp-static-files.s3.eu-central-1.amazonaws.com/client-dashboard/welcome.png"
            alt="Welcome"
            sx={{
              width: { xs: '21rem', sm: '31rem' },
            }}
            flex={1}
            mt="auto"
          />
        </Fade>
      </Slide>
      {!hidden && <BottomGradientBar position="absolute" bottom={0} />}
    </Stack>
  );
};
