import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { PasswordInput, ResponsiveBreak } from '@components';

import { authSelectors, resetUserPassword } from '@redux/auth';
import { ApiStatus, AppRoutes } from '@constants';
import { isStatusLoading } from '@utils';

export const ResetPasswordPage: React.FC<{ isNewPassword?: boolean }> = ({
  isNewPassword,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { apiStatus } = useAppSelector(authSelectors.getResetPasswordAPIData);
  const token = searchParams.get('token');

  const isLoading = isStatusLoading(apiStatus);

  const formik = useFormik<{
    password: string;
    confirmPassword: string;
  }>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: yup.object({
      password: yup.string().max(255).required('Password is required'),
      confirmPassword: yup
        .string()
        .max(255)
        .required('Password is required')
        .when(['password'], (formData, schema) => {
          const password = formData[0];

          if (password) return schema.oneOf(formData, 'Passwords should match');

          return schema;
        }),
    }),
    onSubmit: async (values) => {
      if (token) {
        dispatch(resetUserPassword({ password: values.password, token }));
      }
    },
  });

  useEffect(() => {
    if (apiStatus === ApiStatus.COMPLETE) {
      navigate(`/${AppRoutes.SIGN_IN}`);
    }
  }, [apiStatus]);

  return (
    <>
      {isNewPassword ? (
        <Typography variant="h2" mb={{ xs: 2, md: 6 }}>
          Welcome 👋 <ResponsiveBreak breakpoint="xs" />
          To get started, please take a moment to create your password
        </Typography>
      ) : (
        <Typography variant="h2" mb={{ xs: 2, md: 6 }}>
          Create <ResponsiveBreak breakpoint="xs" />
          your new password
        </Typography>
      )}
      <Box display="flex" flexDirection="column" gap={2}>
        <PasswordInput
          name="password"
          label="New Password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && !!formik.errors.password}
          helperText={
            formik.touched.password && formik.errors.password
              ? formik.errors.password
              : undefined
          }
        />
        <PasswordInput
          name="confirmPassword"
          label="Confirm Password"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.confirmPassword && !!formik.errors.confirmPassword
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
              ? formik.errors.confirmPassword
              : undefined
          }
        />
        <Box>
          <Button
            variant="contained"
            onClick={() => {
              formik.handleSubmit();
            }}
            disabled={!formik.dirty || isLoading}
          >
            {isLoading && (
              <CircularProgress
                size={16}
                color="secondary"
                sx={{ marginRight: 1 }}
              />
            )}
            Save and login
          </Button>
        </Box>
      </Box>
    </>
  );
};
