import React from 'react';

import { Box, Typography } from '@mui/material';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';

import { ICandidateDetails } from '@types';

interface ICandidateDetailsHeader {
  data: ICandidateDetails;
  headerText?: React.ReactNode;
}

export const CandidateDetailsHeader: React.FC<ICandidateDetailsHeader> = ({
  data,
  headerText,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap="0.75rem"
      justifyContent={{ xs: 'center', md: 'flex-start' }}
    >
      <Box
        sx={(theme) => ({
          justifyContent: 'center',
          width: 45,
          height: 45,
          alignItems: 'center',
          background: theme.palette.brand.accent,
          borderRadius: '0.5rem',
          display: {
            xs: 'none',
            md: 'flex',
          },
        })}
      >
        <PersonAddOutlinedIcon
          sx={{
            color: 'white',
          }}
        />
      </Box>
      <Box textAlign={{ xs: 'center', md: 'start' }}>
        {headerText ? (
          <Typography variant="h2">{headerText}</Typography>
        ) : (
          <>
            <Typography variant="h2">{`${data?.profile?.firstName} ${data?.profile?.lastName}`}</Typography>
            <Typography variant="body2" color="text.secondary">
              {`${data.profile?.city}, ${data.profile?.country}`}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};
