import { ReactNode } from 'react';

import { Box } from '@mui/material';
import { BottomGradientBar, BrandLogo } from '@components';

export const AuthLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      flexDirection="column"
      sx={{ background: { xs: '#FFF', md: '#F6F8FC' } }}
      position="relative"
    >
      <Box
        sx={{
          position: { xs: 'relative', md: 'absolute' },
          top: 0,
          left: 0,
        }}
        pt={{ xs: 2, md: 3 }}
        pl={{ xs: 2, md: 6 }}
      >
        <BrandLogo />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
        <Box
          sx={{
            maxWidth: { xs: '100%', md: '570px' },
            width: '100%',
            margin: { xs: '0', md: '16px 24px' },
            borderRadius: { xs: '0', md: '16px' },
            height: { xs: '100%', md: 'auto' },
            boxShadow: { xs: 'none', md: '0px 8px 16px 0px #8F95B226' },
            display: 'flex',
            flexDirection: 'column',
            background: '#FFF',
          }}
        >
          <Box
            sx={{ padding: { xs: '36px 16px 16px 16px', md: '63px 58px' } }}
            flex={1}
          >
            {children}
          </Box>
          <BottomGradientBar />
        </Box>
      </Box>
    </Box>
  );
};
