import { ReactNode, useMemo } from 'react';

import { isNumber } from 'lodash';

import { Box, Link, Typography } from '@mui/material';
import { RowsDataLayout } from 'components/common';

import { ICandidateDetails } from '@types';
import { resumeFileUrl } from '@constants';

export const CandidateDetailsProfile: React.FC<{ data: ICandidateDetails }> = ({
  data,
}) => {
  const mainProfileRowsData = useMemo(() => {
    const res: { label: string; value: string | ReactNode }[] = [];

    if (
      data.profile?.primarySkills?.length ||
      data.profile?.secondarySkills?.length
    )
      res.push({
        label: 'Skills',
        value: (
          <Box display="flex" flexDirection="column" gap={1}>
            <Box display={'flex'} gap={1}>
              {data.profile?.primarySkills?.map((item) => (
                <Typography
                  key={`skills_${item}`}
                  variant="body1"
                  color="text.primary"
                  sx={{
                    background: '#F6F8FC',
                    padding: '5px',
                    borderRadius: '4px',
                  }}
                >
                  {item}
                </Typography>
              ))}
            </Box>
            <Box ml="5px">
              <Typography variant="body2" color="text.secondary">
                {data.profile?.secondarySkills?.join(', ')}
              </Typography>
            </Box>
          </Box>
        ),
      });

    if (isNumber(data.profile?.experience))
      res.push({
        label: 'Experience',
        value: `${data.profile?.experience} years`,
      });
    if (data.profile?.seniority)
      res.push({ label: 'Seniority', value: data.profile?.seniority });
    if (data.profile?.noticePeriod)
      res.push({ label: 'Notice period', value: data.profile?.noticePeriod });

    if (data.profile?.city || data.profile?.country)
      res.push({
        label: 'Location',
        value: `${data.profile?.city}, ${data.profile.country}`,
      });
    if (data.profile?.englishLevel)
      res.push({ label: 'English', value: data.profile?.englishLevel });

    if (data.profile?.resumeName)
      res.push({
        label: 'Full resume',
        value: (
          <Link
            href={resumeFileUrl(data.profile?.resumeName)}
            component={'a'}
            target="_blank"
            color="text.link"
            sx={{ cursor: 'pointer' }}
            fontFamily="inherit"
          >
            {data.profile?.resumeName} ↗
          </Link>
        ),
      });

    return res;
  }, [data]);

  return <RowsDataLayout data={mainProfileRowsData} />;
};
