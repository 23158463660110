import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  CandidateDetailsHeader,
  FeedbackAndSchedulingModal,
  FeedbackAndSchedulingSuccessModal,
  ResolutionContainer,
} from '@components';
import { AdditionalDetails } from './components/AdditionalDetails';

import { Box, Button, Stack, Typography } from '@mui/material';

import { isStatusLoading } from '@utils';
import { validationSchema } from './validationSchema';

import {
  candidateDetailsSelectors,
  provideClientInterviewFeedback,
  setCandidateDetailsAction,
} from '@redux/candidateDetails';

import { ClientInterviewFeedbackFormValues } from '@types';
import {
  CandidateDetailsAction,
  CandidateDetailsActionMapToResolution,
  CandidateStatus,
  ClientInterviewFeedbackResolution,
  SearchParams,
} from '@constants';
import { usePersistUserFeedback } from '@hooks';
import { isEqual } from 'lodash';

const ClientFeedbackResolutionMap: Record<
  ClientInterviewFeedbackResolution,
  React.ReactNode
> = {
  [ClientInterviewFeedbackResolution.Passed]: <>Passed the interview</>,
  [ClientInterviewFeedbackResolution.Failed]: (
    <>
      Failed,{' '}
      <Box component={'span'} fontWeight={400}>
        keep interviewing other candidates
      </Box>
    </>
  ),
};

export const ClientInterviewFeedbackModal = () => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line
  const [_, setSearchParams] = useSearchParams();

  const { provideClientInterviewFeedbackAPIStatus, data } = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );
  const candidateDetailsAction = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsAction,
  );

  const [savedInterviewFeedback, setSavedInterviewFeedback] =
    usePersistUserFeedback<Partial<ClientInterviewFeedbackFormValues>>(
      'interview-feedback',
    );

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState<boolean>(false);

  const isLoading = isStatusLoading(provideClientInterviewFeedbackAPIStatus);

  const formik = useFormik<ClientInterviewFeedbackFormValues>({
    initialValues: {
      resolution: null,
      rejectionReasons: [],
      details: null,
      ...(savedInterviewFeedback || {}),
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      if (data?.id) {
        const res = await dispatch(
          provideClientInterviewFeedback({
            candidateId: data.id,
            resolution: values.resolution!,
            rejectionReasons: values.rejectionReasons!,
            details: values.details,
          }),
        );
        if (provideClientInterviewFeedback.fulfilled.match(res)) {
          handleModalClose();
          setSuccessModalOpen(true);
        }
      }
    },
  });

  useEffect(() => {
    if (!isEqual(savedInterviewFeedback, formik.values)) {
      setSavedInterviewFeedback(formik.values);
    }
  }, [savedInterviewFeedback, formik.values]);

  useEffect(() => {
    if (candidateDetailsAction) {
      if (
        [
          CandidateDetailsAction.CLIENT_INTERVIEW_PASSED,
          CandidateDetailsAction.CLIENT_INTERVIEW_FAILED,
        ].includes(candidateDetailsAction)
      ) {
        formik.setFieldValue(
          'resolution',
          CandidateDetailsActionMapToResolution[candidateDetailsAction],
        );
        setModalOpen(true);
      }
    } else {
      formik.resetForm();
      setModalOpen(false);
    }
  }, [candidateDetailsAction]);

  const handleModalClose = () => {
    dispatch(setCandidateDetailsAction(null));
    setSearchParams(
      (_searchParams) => {
        _searchParams.delete(SearchParams.ACTION);
        return _searchParams;
      },
      { replace: true },
    );
  };

  if (!data) return null;

  return (
    <>
      <FeedbackAndSchedulingModal
        isOpen={isModalOpen}
        onClose={() => {
          handleModalClose();
          setSavedInterviewFeedback({});
        }}
        title={<CandidateDetailsHeader data={data} />}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        submitLabel={'Submit'}
        additionalAction={
          <Button
            variant="contained"
            color="secondary"
            onClick={handleModalClose}
          >
            Review profile
          </Button>
        }
      >
        <Stack gap="1.5rem">
          <ResolutionContainer
            resolution={
              formik.values
                .resolution as unknown as ClientInterviewFeedbackResolution
            }
          >
            <Typography variant="body2" color="common.white" fontWeight={600}>
              {ClientFeedbackResolutionMap[formik.values.resolution!]}
            </Typography>
          </ResolutionContainer>
          <AdditionalDetails formik={formik} candidateDetails={data} />
        </Stack>
      </FeedbackAndSchedulingModal>
      <FeedbackAndSchedulingSuccessModal
        data={data}
        isOpen={isSuccessModalOpen}
        isPassed={data.status === CandidateStatus.CallPassed}
        label={
          <>
            {data.status === CandidateStatus.CallPassed ? (
              <>
                Thank you!
                <br />
                We’ll proceed with this candidate
              </>
            ) : (
              <>
                Thank you for your feedback and your time!
                <br />
                We’ll keep searching and interviewing candidates.
              </>
            )}
          </>
        }
        subLabel={''}
        handleClose={() => setSuccessModalOpen(false)}
      />
    </>
  );
};
