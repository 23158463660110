import { useLocation } from 'react-router-dom';
import { StandalonePages } from '@constants';

export const useIsStandalonePage = () => {
  const location = useLocation();

  return StandalonePages.some((appRoute) =>
    location.pathname.includes(appRoute),
  );
};
