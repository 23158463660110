import {
  ACCESS_TOKEN_LOCALSTORAGE_KEY,
  ApiStatus,
  REFRESH_TOKEN_COOKIE_KEY,
} from '@constants';
import Cookies from 'js-cookie';

export const isLocalhost =
  window.location.hostname.includes('localhost') ||
  window.location.hostname.startsWith('127.') ||
  window.location.hostname.startsWith('10.') ||
  window.location.hostname.startsWith('172.') ||
  window.location.hostname.startsWith('192.');

export function setRefreshToken(token: string | null) {
  if (typeof window != 'undefined') {
    token
      ? Cookies.set(REFRESH_TOKEN_COOKIE_KEY, token, {
          expires: 7,
        })
      : Cookies.remove(REFRESH_TOKEN_COOKIE_KEY);
  }
}

export function setAccessToken(token: string | null) {
  if (typeof window != 'undefined') {
    token
      ? localStorage.setItem(ACCESS_TOKEN_LOCALSTORAGE_KEY, token)
      : localStorage.removeItem(ACCESS_TOKEN_LOCALSTORAGE_KEY);
  }
}

export function getAccessToken() {
  if (typeof window != 'undefined') {
    const token = localStorage?.getItem(ACCESS_TOKEN_LOCALSTORAGE_KEY);
    return token || null;
  }
}

export const isStatusLoading = (status: ApiStatus) =>
  status === ApiStatus.LOADING;
export const isStatusComplete = (status: ApiStatus) =>
  status === ApiStatus.COMPLETE;

export const isStatusIdle = (status: ApiStatus) => status === ApiStatus.IDLE;
export const isStatusFailed = (status: ApiStatus) =>
  status === ApiStatus.FAILED;
export const isStatusForbidden = (status: ApiStatus) =>
  status === ApiStatus.FORBIDDEN;
