import { ResponsiveBreak, TextButton } from '@components';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';

export const ForgotPasswordForm: React.FC<{
  username: string;
  isLoading: boolean;
  onChangeUsername: (value: string) => void;
  onBack: () => void;
  onSend: () => void;
}> = ({ username, isLoading, onChangeUsername, onBack, onSend }) => {
  const isSubmitDisabled = isLoading || !username.trim();

  return (
    <>
      <Box display="flex" alignItems="center" mb={{ xs: 2, md: 6 }}>
        <Typography variant="h2">
          Reset <ResponsiveBreak breakpoint="xs" /> your password
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        width="100%"
        maxWidth={{ xs: '100%', md: '320px' }}
        mb={4}
      >
        <TextField
          name="username"
          label="Email"
          variant="outlined"
          fullWidth
          type="email"
          value={username}
          onChange={(e) => onChangeUsername(e.target.value)}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Button
            variant="contained"
            onClick={onSend}
            disabled={isSubmitDisabled}
          >
            {isLoading && (
              <CircularProgress
                size={16}
                color="secondary"
                sx={{ marginRight: 1 }}
              />
            )}
            Send email
          </Button>
          <TextButton variant="secondary" onClick={onBack}>
            ← Back to login
          </TextButton>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="body2" color="text.lightGrey">
          You will receive an email with instructions and a link to reset your
          password.
        </Typography>
      </Box>
    </>
  );
};
