import { TextButton } from '@components';
import { Box, Typography } from '@mui/material';

export const ForgotPasswordEmailSent: React.FC<{
  username: string;
  onReset: () => void;
  onResendEmail: () => void;
}> = ({ username, onReset, onResendEmail }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={8}
        maxWidth={{ xs: '100%', md: '320px' }}
        sx={{ margin: 'auto' }}
      >
        <Box display="flex" flexDirection="column" alignItems="center" gap={3}>
          <img
            src="https://ae-cp-static-files.s3.eu-central-1.amazonaws.com/client-dashboard/email.png"
            alt="email"
            width={90}
            height={75}
          />

          <Typography variant="h2" textAlign="center">
            Please check your inbox
          </Typography>

          <Typography variant="body1" textAlign="center">
            A password reset link has been sent to your email. Please check your
            inbox and follow the instructions.
          </Typography>

          <Typography variant="body1" color="text.secondary" textAlign="center">
            {username}
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          gap={2}
          width="100%"
          maxWidth="280px"
        >
          <Box>
            <Typography variant="body2" color="text.secondary">
              Incorrect email?
            </Typography>
            <TextButton variant="secondary" color="text.link" onClick={onReset}>
              Change email
            </TextButton>
          </Box>
          <Box>
            <Typography variant="body2" color="text.secondary">
              Haven’t received an email?
            </Typography>
            <TextButton
              variant="secondary"
              color="text.link"
              onClick={onResendEmail}
            >
              Resend email
            </TextButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
