import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, List, ListSubheader } from '@mui/material';
import { BrandLogo, ReportIssue } from '@components';
import { LinkDrawer } from './LinkDrawer';
import { UserProfile } from './UserProfile';
import StarIcon from '@mui/icons-material/Star';
import ContactsRoundedIcon from '@mui/icons-material/ContactsRounded';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NextWeekOutlinedIcon from '@mui/icons-material/NextWeekOutlined';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';

import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { authSelectors, logoutUser } from '@redux/auth';

import { isSamePage } from '@utils';
import { AppRoutes } from '@constants';

export const DrawerContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: profile } = useAppSelector(authSelectors.getProfileAPIData);
  const [isReportIssueModalOpen, setIsReportIssueModalOpen] = useState(false);

  const onOpenReportIssueModal = useCallback(
    () => setIsReportIssueModalOpen(true),
    [],
  );
  const onCloseReportIssueModal = useCallback(
    () => setIsReportIssueModalOpen(false),
    [],
  );
  const handleLogout = async () => {
    await dispatch(logoutUser());
    navigate(`/${AppRoutes.SIGN_IN}`);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        px={1}
        py={2}
        height="100%"
        overflow="auto"
      >
        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
            marginLeft: 2,
          }}
        >
          <BrandLogo />
        </Box>
        <Box flex={1}>
          <List component="nav">
            <ListSubheader disableSticky>People</ListSubheader>
            <LinkDrawer
              Icon={StarIcon}
              isActive={isSamePage(location.pathname, AppRoutes.TEAMS)}
              label="My teams"
              href={`/${AppRoutes.TEAMS}`}
              disabled
            />
            <LinkDrawer
              Icon={ContactsRoundedIcon}
              isActive={isSamePage(location.pathname, AppRoutes.CANDIDATES)}
              label="Candidates"
              href={`/${AppRoutes.CANDIDATES}`}
            />

            <ListSubheader disableSticky>Budgets</ListSubheader>
            <LinkDrawer
              Icon={AccessTimeIcon}
              isActive={isSamePage(location.pathname, AppRoutes.BILLED_HOURS)}
              label="Billed hours"
              href={`/${AppRoutes.BILLED_HOURS}`}
              disabled
            />
            <LinkDrawer
              Icon={NextWeekOutlinedIcon}
              isActive={isSamePage(location.pathname, AppRoutes.SOW)}
              label="SOWs"
              href={`/${AppRoutes.SOW}`}
              disabled
            />

            <ListSubheader disableSticky>Feedback</ListSubheader>
            <LinkDrawer
              Icon={InsertEmoticonIcon}
              isActive={isSamePage(location.pathname, AppRoutes.SURVEYS)}
              label="Satisfaction surveys"
              href={`/${AppRoutes.SURVEYS}`}
              disabled
            />
            <LinkDrawer
              Icon={ChatOutlinedIcon}
              label="Report issue"
              onClick={onOpenReportIssueModal}
            />
          </List>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          gap={1}
          marginLeft={2}
          marginTop={2}
        >
          <UserProfile profile={profile} />
          <Button
            variant="text"
            color="info"
            size="small"
            onClick={handleLogout}
          >
            Log out
          </Button>
        </Box>
      </Box>
      <ReportIssue
        isOpen={isReportIssueModalOpen}
        onClose={onCloseReportIssueModal}
      />
    </>
  );
};
