import React from 'react';

import {
  FeedbackAndSchedulingModal,
  CandidateDetailsFeedbackNotes,
} from '@components';
import { CandidateDetailsHeader } from '@components';

import { Box, Stack, Typography } from '@mui/material';

import { ICandidateDetails } from '@types';

interface IFeedbackAndSchedulingSuccessModalProps {
  data: ICandidateDetails;
  isOpen: boolean;
  isPassed: boolean;
  label: React.ReactNode;
  subLabel: React.ReactNode;
  handleClose: () => void;
}

export const FeedbackAndSchedulingSuccessModal: React.FC<
  IFeedbackAndSchedulingSuccessModalProps
> = ({ data, isOpen, isPassed, label, subLabel, handleClose }) => {
  if (!data) return null;

  return (
    <FeedbackAndSchedulingModal
      isOpen={isOpen}
      onClose={handleClose}
      isLoading={false}
      title={<CandidateDetailsHeader data={data} />}
      onSubmit={handleClose}
      submitLabel={'Done'}
    >
      <Stack gap="1.5rem">
        <Box
          sx={(theme) => ({
            background: theme.palette.highlight.sectionLight,
            borderRadius: '8px',
            padding: '48px 63px 72px 63px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '14px',
          })}
        >
          {isPassed && (
            <img
              src="https://ae-cp-static-files.s3.eu-central-1.amazonaws.com/client-dashboard/star.png"
              alt="star"
              width="57px"
              height="57px"
            />
          )}
          {label && (
            <Typography variant="h6" textAlign="center">
              {label}
            </Typography>
          )}
          {subLabel && (
            <Typography
              variant="body2"
              color="text.secondary"
              textAlign="center"
            >
              {subLabel}
            </Typography>
          )}
        </Box>
        <CandidateDetailsFeedbackNotes data={data} />
      </Stack>
    </FeedbackAndSchedulingModal>
  );
};
