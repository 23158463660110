import { useCallback, useMemo } from 'react';
import { useAppDispatch } from '@redux/hooks';
import { format } from 'date-fns';

import { Box, Button, Typography } from '@mui/material';

import { ResolutionContainer } from '@components';

import { ICandidateDetails } from '@types';
import {
  canRescheduleCall,
  canRescheduleOrCancelCall,
  isClientInterviewDone,
} from '@utils';
import { CandidateDetailsAction, CandidateStatus } from '@constants';
import { setCandidateDetailsAction } from '@redux/candidateDetails';

const ClientFeedbackResolutionMap: Record<
  CandidateStatus,
  string | JSX.Element | ((data?: ICandidateDetails) => JSX.Element)
> = {
  [CandidateStatus.CallRequested]: 'Approved for an interview',
  [CandidateStatus.CallCancelled]: 'Interview cancelled',
  [CandidateStatus.CallScheduled]: (data) => (
    <>
      {isClientInterviewDone(
        data?.interview?.scheduledSlot?.[0] || null,
        data?.interview?.clientTimezone || null,
      )
        ? 'Interview feedback is pending'
        : 'Interview scheduled'}
    </>
  ),
  [CandidateStatus.CallPassed]: 'Passed the interview',
  [CandidateStatus.CallFailed]: (data) => (
    <>
      CI failed:{' '}
      <Box component="span" fontWeight={400}>
        {(data?.interview?.result?.rejectionReason || [])?.join(', ')}
      </Box>
    </>
  ),
  [CandidateStatus.Rejected]: (data) => (
    <>
      Skipped:{' '}
      <Box component="span" fontWeight={400}>
        {(data?.clientFeedback?.rejectionReason || [])?.join(', ')}
      </Box>
    </>
  ),
  [CandidateStatus.Submitted]: 'Submitted',
  [CandidateStatus.Hired]: 'Hired',
};

export const ClientResolution: React.FC<{
  data: ICandidateDetails;
}> = ({ data }) => {
  const dispatch = useAppDispatch();

  const isCallCancelled = data.status === CandidateStatus.CallCancelled;

  const handleActionButtonClick = useCallback(
    (action: CandidateDetailsAction) => {
      dispatch(setCandidateDetailsAction(action));
    },
    [],
  );

  const statusLabel = useMemo(() => {
    const label = ClientFeedbackResolutionMap[data.status];
    return typeof label === 'function' ? label(data) : label;
  }, [data]);

  const date = useMemo(() => {
    switch (data.status) {
      case CandidateStatus.CallRequested:
        return data.interview?.requestedSlot?.[0].start;
      case CandidateStatus.CallScheduled:
        return data.interview?.scheduledSlot?.[0].start;
      case CandidateStatus.CallCancelled:
        return data.interview?.cancellation?.cancelledAt;
      case CandidateStatus.CallPassed:
      case CandidateStatus.CallFailed:
        return data.interview?.result?.providedAt;
      default:
        return data.clientFeedback?.providedAt;
    }
  }, [data]);

  return (
    <ResolutionContainer resolution={data.status}>
      <Typography
        variant="body2"
        color="common.white"
        fontWeight={600}
        flex={1}
      >
        {statusLabel}
      </Typography>
      {date && (
        <Typography
          variant="body2"
          color="common.white"
          fontWeight={300}
          sx={{ textDecoration: isCallCancelled ? 'line-through' : 'none' }}
        >
          {format(new Date(date), 'EEE, MMM dd, hh:mm a')}
        </Typography>
      )}
      {canRescheduleOrCancelCall(data.status) && (
        <Button
          size="small"
          variant="contained"
          sx={{
            bgcolor: 'rgba(255, 255, 255, 0.15)',
            ':hover': {
              bgcolor: 'rgba(255, 255, 255, 0.10)',
            },
          }}
          onClick={() =>
            handleActionButtonClick(CandidateDetailsAction.RESCHEDULE_INTERVIEW)
          }
        >
          Cancel or reschedule
        </Button>
      )}
      {canRescheduleCall(data.status) && (
        <Button
          variant="contained"
          size="small"
          onClick={() =>
            handleActionButtonClick(CandidateDetailsAction.RESCHEDULE_INTERVIEW)
          }
        >
          Reschedule
        </Button>
      )}
    </ResolutionContainer>
  );
};
