import { Box, Typography } from '@mui/material';

export const EmptyPositonsState: React.FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src="https://ae-cp-static-files.s3.eu-central-1.amazonaws.com/client-dashboard/puzzle.png"
          alt="puzzle"
        />
        <Typography variant="h2" m="0.5rem">
          You don’t have any open positions
        </Typography>
      </Box>
    </Box>
  );
};
