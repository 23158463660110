import React from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SlideTransition } from '@components';

interface IFeedbackDialogProps {
  isOpen: boolean;
  onClose: () => void;
  title: React.ReactNode;
  children: React.ReactNode;
  onSubmit: () => void;
  isLoading: boolean;
  submitLabel: string;
  actions?: React.ReactNode;
  additionalAction?: React.ReactNode;
}

export const FeedbackAndSchedulingModal: React.FC<IFeedbackDialogProps> = ({
  isOpen,
  onClose,
  title,
  children,
  onSubmit,
  isLoading,
  submitLabel,
  actions,
  additionalAction = null,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: {
            xs: '1rem 1rem 2rem',
            md: '2rem 3rem',
          },
          position: 'relative',
          maxWidth: {
            xs: '100%',
            md: '34.5rem',
          },
        },
      }}
      TransitionComponent={SlideTransition}
    >
      <IconButton
        sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle sx={{ p: '0 0 1.5rem' }} component="div">
        {title}
      </DialogTitle>

      <DialogContent sx={{ px: 0 }}>{children}</DialogContent>

      <DialogActions sx={{ p: 0, gap: '0.5rem', justifyContent: 'flex-start' }}>
        {actions ? (
          actions
        ) : (
          <>
            <Button
              variant="contained"
              onClick={onSubmit}
              disabled={isLoading}
              startIcon={
                isLoading && <CircularProgress size={16} color="secondary" />
              }
            >
              {submitLabel}
            </Button>
            {additionalAction}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
