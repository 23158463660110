import dayjs from 'dayjs';
import { convertOffsetToHours } from './timezone';

export const geDateTimeInTimezone = (
  date: string | null,
  timezone: string | null,
) => {
  return dayjs
    .utc(date)
    .add(Number(convertOffsetToHours(timezone ?? '+00:00')), 'hour');
};

const pad = (value: number): string => {
  return value < 10 ? '0' + value : value.toString();
};

export const getUserOffset = (): string => {
  const date = new Date();
  const sign = date.getTimezoneOffset() > 0 ? '-' : '+';
  const offset = Math.abs(date.getTimezoneOffset());
  const hours = pad(Math.floor(offset / 60));
  const minutes = pad(offset % 60);
  return sign + hours + ':' + minutes;
};
