import React from 'react';
import dayjs from 'dayjs';

import { Box, Stack, Typography } from '@mui/material';

import { ICandidateDetails } from '@types';
import { geDateTimeInTimezone } from '@utils';

export const CandidateDetailsFeedbackNotes: React.FC<{
  data: ICandidateDetails;
}> = ({ data }) => {
  return (
    <>
      {!!data.clientFeedback && (
        <Box
          sx={(theme) => ({
            background: theme.palette.background.warningFill,
            paddingX: '24px',
            paddingY: '16px',
            borderRadius: '8px',
          })}
          display="flex"
          flexDirection="column"
          gap={'1rem'}
        >
          {data.interview?.result && (
            <Stack gap={'0.25rem'}>
              <Typography variant="body2" color="text.secondary">
                Interviewed by {data.interview.result.reviewer?.name} on{' '}
                {dayjs(data.interview.result.providedAt).format('MMM DD')}
              </Typography>
              <Typography variant="body1" whiteSpace="pre-line">
                {data.interview.result.feedback}
              </Typography>
            </Stack>
          )}
          {data.interview?.cancellation && (
            <Stack gap={'0.25rem'}>
              <Typography variant="body2" color="text.secondary">
                Interview cancelled by{' '}
                {data.interview.cancellation.cancelledBy?.name} on{' '}
                {dayjs(data.interview.cancellation.cancelledAt).format(
                  'MMM DD',
                )}
              </Typography>
              <Typography variant="body1" whiteSpace="pre-line">
                {data.interview.cancellation?.reason}
              </Typography>
            </Stack>
          )}
          <Stack gap={'0.25rem'}>
            <Typography variant="body2" color="text.secondary">
              Reviewed by {data.clientFeedback.reviewer?.name} on{' '}
              {dayjs(data.clientFeedback.providedAt).format('MMM DD')}
            </Typography>
            <Typography variant="body1" whiteSpace="pre-line">
              {data.clientFeedback.feedback}
            </Typography>
          </Stack>
          {!!data.interview?.requestedSlot?.length && (
            <Box>
              <Typography variant="body2" color="text.secondary">
                Interview slots
              </Typography>
              {data.interview.requestedSlot.map((slot, index) => (
                <Typography
                  key={`slot_${index}`}
                  variant="body1"
                  color="text.primary"
                >
                  {`${geDateTimeInTimezone(
                    slot.start,
                    data.interview?.clientTimezone || null,
                  ).format('ddd, MMM DD, hh:mm a')} - ${geDateTimeInTimezone(
                    new Date(slot.end || '').toISOString(),
                    data.interview?.clientTimezone || null,
                  ).format('hh:mm a')}`}
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
