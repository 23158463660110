export enum CandidateStatus {
  Submitted = 'Submitted',
  CallRequested = 'Call Requested',
  CallCancelled = 'Call Cancelled',
  CallScheduled = 'Call Scheduled',
  CallPassed = 'Call Passed',
  CallFailed = 'Call Failed',
  Rejected = 'Rejected',
  Hired = 'Hired',
}

export enum ClientFeedbackResolution {
  CallRequested = 'Call Requested',
  Rejected = 'Rejected',
  Hired = 'Hired',
}

export enum ClientInterviewFeedbackResolution {
  Passed = 'Passed',
  Failed = 'Failed',
}

export enum CandidateDetailsAction {
  SCHEDULE_INTERVIEW = 'schedule_interview',
  SKIP_CANDIDATE = 'skip_candidate',
  RESCHEDULE_INTERVIEW = 'reschedule_interview',
  CANCEL_INTERVIEW = 'cancel_interview',
  CLIENT_INTERVIEW_PASSED = 'client_interview_passed',
  CLIENT_INTERVIEW_FAILED = 'client_interview_failed',
}

export const CandidateDetailsActionMapToResolution: Partial<
  Record<
    CandidateDetailsAction,
    ClientFeedbackResolution | ClientInterviewFeedbackResolution
  >
> = {
  [CandidateDetailsAction.SCHEDULE_INTERVIEW]:
    ClientFeedbackResolution.CallRequested,
  [CandidateDetailsAction.SKIP_CANDIDATE]: ClientFeedbackResolution.Rejected,
  [CandidateDetailsAction.CLIENT_INTERVIEW_PASSED]:
    ClientInterviewFeedbackResolution.Passed,
  [CandidateDetailsAction.CLIENT_INTERVIEW_FAILED]:
    ClientInterviewFeedbackResolution.Failed,
};

export const CandidateStatusLabel: Record<CandidateStatus, string> = {
  [CandidateStatus.Submitted]: 'Submitted',
  [CandidateStatus.CallRequested]: 'Setting up interview',
  [CandidateStatus.CallCancelled]: 'Interview cancelled',
  [CandidateStatus.CallScheduled]: 'Interview scheduled',
  [CandidateStatus.CallPassed]: 'Passed interview',
  [CandidateStatus.CallFailed]: 'Failed interview',
  [CandidateStatus.Rejected]: 'Rejected',
  [CandidateStatus.Hired]: 'Hired',
};

export const ChipColorByCandidateStatus: Record<
  CandidateStatus,
  'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
> = {
  [CandidateStatus.Submitted]: 'success',
  [CandidateStatus.CallRequested]: 'default',
  [CandidateStatus.CallCancelled]: 'default',
  [CandidateStatus.CallScheduled]: 'success',
  [CandidateStatus.CallPassed]: 'success',
  [CandidateStatus.CallFailed]: 'default',
  [CandidateStatus.Rejected]: 'default',
  [CandidateStatus.Hired]: 'success',
};

export const ResolutionColorByCandidateStatus: Record<
  CandidateStatus | ClientInterviewFeedbackResolution,
  'text.success' | 'text.secondary'
> = {
  [CandidateStatus.Submitted]: 'text.success',
  [CandidateStatus.CallRequested]: 'text.success',
  [CandidateStatus.CallCancelled]: 'text.secondary',
  [CandidateStatus.CallScheduled]: 'text.success',
  [CandidateStatus.CallPassed]: 'text.success',
  [CandidateStatus.CallFailed]: 'text.secondary',
  [CandidateStatus.Rejected]: 'text.secondary',
  [CandidateStatus.Hired]: 'text.success',
  [ClientInterviewFeedbackResolution.Passed]: 'text.success',
  [ClientInterviewFeedbackResolution.Failed]: 'text.secondary',
};

export enum InterviewSlotOption {
  Date = 'date',
  MyCalendar = 'myCalendar',
}

export const CANDIDATE_INTERVIEW_CANCELLED = 'CANDIDATE_INTERVIEW_CANCELLED';
