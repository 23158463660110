import axios from 'axios';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  matchPath,
} from 'react-router-dom';

import { AppRoutes, AuthPages, SearchParams } from '@constants';
import { getAccessToken, isLocalhost } from '@utils';
import { useAppDispatch } from '@redux/hooks';
import { refreshUserToken } from '@redux/auth';

export const useAxiosConfig = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  axios.defaults.baseURL = isLocalhost
    ? `${process.env.REACT_APP_API_URL}/api`
    : '/api';

  const handleNavigateToSignIn = () => {
    const isAuthPage = AuthPages.some((authPage) =>
      matchPath(authPage, location.pathname),
    );
    navigate({
      pathname: `/${AppRoutes.SIGN_IN}`,
      search: !isAuthPage
        ? createSearchParams({
            [SearchParams.REDIRECT]: `${location.pathname}${location.search}`,
          }).toString()
        : '',
    });
  };

  axios.interceptors.request.use((config) => {
    const token = getAccessToken();

    if (token) {
      config.headers['Authorization'] = token;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          await dispatch(refreshUserToken());

          if (getAccessToken()) {
            return axios(originalRequest);
          } else {
            handleNavigateToSignIn();
          }
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
        }
      }

      if (error.response.status === 401 && originalRequest._retry) {
        handleNavigateToSignIn();
      }

      return Promise.reject(error);
    },
  );
};
