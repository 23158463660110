import React, { useMemo } from 'react';
import { useAppDispatch } from '@redux/hooks';

import { Box, Button, Paper, styled } from '@mui/material';

import { CandidateDetailsAction, CandidateStatus } from '@constants';
import { ICandidateDetails } from '@types';
import { setCandidateDetailsAction } from '@redux/candidateDetails';
import { isClientInterviewDone } from '@utils';
import { isEmpty } from 'lodash';

interface ICandidaterDetailsActionButtonProps {
  data: ICandidateDetails;
}

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: '0.5rem',
  padding: '0.375rem 1rem',
  textWrap: 'nowrap',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

export const CandidaterDetailsActionButton: React.FC<
  ICandidaterDetailsActionButtonProps
> = ({ data }) => {
  const dispatch = useAppDispatch();

  const handleActionButtonClick = (action: CandidateDetailsAction) => {
    dispatch(setCandidateDetailsAction(action));
  };

  const isCIDone = useMemo(() => {
    return (
      isEmpty(data.interview?.result) &&
      isClientInterviewDone(
        data.interview?.scheduledSlot?.[0] || null,
        data.interview?.clientTimezone || null,
      )
    );
  }, [data.interview]);

  if (data.status !== CandidateStatus.Submitted && !isCIDone) {
    return null;
  }

  return (
    <Paper
      sx={{
        padding: {
          xs: '0.5rem',
          md: '1.5rem',
        },
        display: 'flex',
      }}
    >
      {data.status === CandidateStatus.Submitted && (
        <>
          <StyledButton
            size="large"
            variant="contained"
            onClick={() =>
              handleActionButtonClick(CandidateDetailsAction.SCHEDULE_INTERVIEW)
            }
          >
            <Box component="span" sx={{ display: { xs: 'block', sm: 'none' } }}>
              Setup interview
            </Box>
            <Box component="span" sx={{ display: { xs: 'none', sm: 'block' } }}>
              Schedule an interview
            </Box>
          </StyledButton>
          <StyledButton
            size="large"
            variant="contained"
            sx={{
              backgroundColor: 'text.secondary',
              '&:hover': {
                backgroundColor: 'text.secondary',
              },
            }}
            onClick={() =>
              handleActionButtonClick(CandidateDetailsAction.SKIP_CANDIDATE)
            }
          >
            Skip this candidate
          </StyledButton>
        </>
      )}
      {isCIDone && (
        <>
          <StyledButton
            size="large"
            variant="contained"
            onClick={() =>
              handleActionButtonClick(
                CandidateDetailsAction.CLIENT_INTERVIEW_PASSED,
              )
            }
          >
            Passed
          </StyledButton>
          <StyledButton
            size="large"
            variant="contained"
            sx={{
              backgroundColor: 'text.secondary',
              '&:hover': {
                backgroundColor: 'text.secondary',
              },
            }}
            onClick={() =>
              handleActionButtonClick(
                CandidateDetailsAction.CLIENT_INTERVIEW_FAILED,
              )
            }
          >
            Failed
          </StyledButton>
        </>
      )}
    </Paper>
  );
};
