import React, { useCallback } from 'react';
import { isEmpty } from 'lodash';

import { Button, Chip, Stack, Typography } from '@mui/material';
import PermContactCalendarRoundedIcon from '@mui/icons-material/PermContactCalendarRounded';
import VideoCallOutlinedIcon from '@mui/icons-material/VideoCallOutlined';

import {
  AppRoutes,
  CandidateDetailsAction,
  CandidateStatus,
  CandidateStatusLabel,
  ChipColorByCandidateStatus,
  SearchParams,
} from '@constants';
import { IPositionCandidate } from '@types';
import { geDateTimeInTimezone, isClientInterviewDone } from '@utils';
import { createSearchParams, useNavigate } from 'react-router-dom';

interface ICandidateListStatusProps {
  candidate: IPositionCandidate;
}

export const CandidateListStatus: React.FC<ICandidateListStatusProps> = ({
  candidate,
}) => {
  const navigate = useNavigate();

  const handleApproveCandidateClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      navigate({
        pathname: `/${AppRoutes.CANDIDATES}/${candidate.id}`,
        search: createSearchParams({
          [SearchParams.ACTION]: CandidateDetailsAction.CLIENT_INTERVIEW_PASSED,
        }).toString(),
      });
    },
    [candidate],
  );

  const renderStatusMessage = () => {
    switch (candidate.status) {
      case CandidateStatus.Submitted:
        return (
          <Button
            size="small"
            variant="contained"
            startIcon={<PermContactCalendarRoundedIcon color="inherit" />}
            sx={{ whiteSpace: 'nowrap', minWidth: 'auto' }}
          >
            Review candidate
          </Button>
        );
      case CandidateStatus.CallScheduled:
        return isClientInterviewDone(
          candidate.scheduledSlot?.[0] || null,
          candidate.clientTimezone,
        ) ? (
          <Button
            size="small"
            variant="contained"
            startIcon={<PermContactCalendarRoundedIcon color="inherit" />}
            sx={{ whiteSpace: 'nowrap', minWidth: 'auto' }}
            onClick={handleApproveCandidateClick}
          >
            Approve interview
          </Button>
        ) : (
          <Stack direction="row" gap={1} alignItems="center">
            <VideoCallOutlinedIcon sx={{ color: 'text.link' }} />
            <Typography>
              {!isEmpty(candidate.scheduledSlot) &&
              candidate.scheduledSlot?.[0].start
                ? geDateTimeInTimezone(
                    candidate.scheduledSlot?.[0].start,
                    candidate.clientTimezone,
                  ).format('MMM DD, hh:mm a')
                : CandidateStatusLabel[candidate.status]}
            </Typography>
          </Stack>
        );
      case CandidateStatus.CallCancelled:
      case CandidateStatus.Hired:
      case CandidateStatus.CallPassed:
      case CandidateStatus.CallFailed:
      case CandidateStatus.Rejected:
      case CandidateStatus.CallRequested:
        return (
          <Chip
            label={CandidateStatusLabel[candidate.status]}
            color={ChipColorByCandidateStatus[candidate.status]}
          />
        );
      default:
        return candidate.status;
    }
  };

  return <>{renderStatusMessage()}</>;
};
