import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';

import { IPositionWithCandidates } from '@types';

export const PositionsList: React.FC<{ data: IPositionWithCandidates[] }> = ({
  data,
}) => {
  return (
    <Box>
      <Typography variant="body1" color="text.secondary" px="1rem">
        Open positions for your account
      </Typography>
      <TableContainer>
        <Table
          sx={{
            tableLayout: 'auto',
            width: '100%',
            borderCollapse: 'separate',
            borderSpacing: '0 1rem',
          }}
        >
          <TableBody>
            {data.map((position) => (
              <TableRow key={position.id}>
                <TableCell
                  colSpan={4}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.background.inputDisabled,
                    padding: '8px 16px',
                    position: 'sticky',
                    border: 'none',
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={2}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <WorkOutlineIcon
                        fontSize="small"
                        sx={{ color: 'rgba(0, 0, 0, 0.56)' }}
                      />
                      <Typography variant="subtitle2">
                        {position.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {position.seniorities.join(', ')}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
