import { useCallback, useEffect, useState, useRef } from 'react';
import { useDebounce } from './useDebounce';

type SetValue<T> = (value: T) => void;

export function usePersistUserFeedback<T>(key: string): [T, SetValue<T>] {
  const USER_FEEDBACK_KEY = 'user-feedback';
  // handler to load previously saved user feedbacks
  const getFeedback = useCallback((): Record<string, T> => {
    if (typeof window === 'undefined') {
      return {};
    }

    try {
      const feedbacks = window.localStorage.getItem(USER_FEEDBACK_KEY);
      return feedbacks ? JSON.parse(feedbacks) : {};
    } catch (error) {
      console.warn(`Error reading saved user feedbacks “${key}”:`, error);
      return {};
    }
  }, [key]);

  const [userFeedback, setUserFeedback] =
    useState<Record<string, T>>(getFeedback);
  const currentFeedback = useRef<T | null>(null);

  // handler to persist updated user feedbacks
  const saveFeedback = () => {
    try {
      if (typeof window !== 'undefined' && currentFeedback.current) {
        window.localStorage.setItem(
          USER_FEEDBACK_KEY,
          JSON.stringify({ ...userFeedback, [key]: currentFeedback.current }),
        );
        setUserFeedback({ ...userFeedback, [key]: currentFeedback.current });
      }
    } catch (error) {
      console.warn(`Error saving user feedbacks: ${error}`);
      return userFeedback;
    }
  };

  const debouncedSave = useDebounce(() => {
    if (currentFeedback.current) {
      saveFeedback();
    }
  }, 300);

  // handler to save incoming user input to existing feedbacks obj.
  const setStepAnswer: SetValue<T> = useCallback((value) => {
    try {
      currentFeedback.current = value;
      debouncedSave();
    } catch (error) {
      console.warn(`Error setting user answer: ${error}`);
    }
  }, []);

  // save user feedbacks on reloads, tab close/change, window close, etc
  useEffect(() => {
    const triggerFeedbackSave = () => {
      if (document.visibilityState === 'hidden') {
        saveFeedback();
      }
    };
    document.addEventListener('visibilitychange', triggerFeedbackSave);
    return () => {
      document.removeEventListener('visibilitychange', triggerFeedbackSave);
    };
  }, []);

  return [userFeedback[key], setStepAnswer];
}
