import { Box, Typography } from '@mui/material';

export const NotFoundPage: React.FC = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    height="100vh"
    color="text.primary"
    flex={1}
  >
    <Box textAlign="center">
      <Typography typography="h1" p={2} fontSize="100px">
        404
      </Typography>
      <Typography typography="h2" p={1}>
        Page Not Found
      </Typography>
      <div>Go back to the previous page</div>
    </Box>
  </Box>
);
