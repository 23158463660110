import axios from 'axios';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { positionsStoreKey } from './positions.const';

import { getPositionsAPI } from '@constants';
import { IPositionWithCandidates } from 'types/position';

export const fetchPositions = createAsyncThunk(
  `${positionsStoreKey}/fetchPosiitons`,
  async (_, { rejectWithValue }) => {
    try {
      const response =
        await axios.get<IPositionWithCandidates[]>(getPositionsAPI);

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);
