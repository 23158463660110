import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import {
  NotFoundPage,
  SignInPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  CandidateDetailsPage,
  CandidatesPage,
} from '@pages';
import { AppLayout, AuthLayout } from '@components';

import { AppRoutes } from '@constants';
import { LayoutWrapper } from './LayoutWrapper';

const AppRouter: FC = () => (
  <Routes>
    <Route path={`/`}>
      <Route index element={<Navigate to={AppRoutes.CANDIDATES} />} />

      <Route element={<LayoutWrapper layout={AppLayout} />}>
        <Route path={AppRoutes.CANDIDATES} element={<CandidatesPage />} />
        <Route
          path={`${AppRoutes.CANDIDATES}/:candidateId`}
          element={<CandidateDetailsPage />}
        />
      </Route>

      <Route element={<LayoutWrapper layout={AuthLayout} />}>
        <Route path={AppRoutes.SIGN_IN} element={<SignInPage />} />
        <Route
          path={AppRoutes.FORGOT_PASSWORD}
          element={<ForgotPasswordPage />}
        />
        <Route
          path={AppRoutes.SET_PASSWORD}
          element={<ResetPasswordPage isNewPassword />}
        />
        <Route
          path={AppRoutes.RESET_PASSWORD}
          element={<ResetPasswordPage />}
        />
      </Route>
    </Route>
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default AppRouter;
