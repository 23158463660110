import React from 'react';

import { Stack } from '@mui/material';

import {
  CandidateStatus,
  ClientInterviewFeedbackResolution,
  ResolutionColorByCandidateStatus,
} from '@constants';

interface IResolutionContainerProps {
  resolution: CandidateStatus | ClientInterviewFeedbackResolution;
  children?: React.ReactNode;
}

export const ResolutionContainer: React.FC<IResolutionContainerProps> = ({
  resolution,
  children,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      padding="0.875rem 1.5rem 1rem"
      borderRadius="0.25rem"
      bgcolor={ResolutionColorByCandidateStatus[resolution]}
      gap="1rem"
    >
      {children}
    </Stack>
  );
};
