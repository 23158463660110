import { Box, Typography } from '@mui/material';
import { ReactNode, useCallback } from 'react';

export const TextButton: React.FC<{
  children?: ReactNode;
  disabled?: boolean;
  onClick: () => void;
  variant?: 'primary' | 'secondary';
  color?: string;
}> = ({ children, disabled, variant, color, onClick }) => {
  const handleClick = useCallback(() => {
    if (!disabled) onClick();
  }, [disabled, onClick]);

  return (
    <Box display="flex">
      <Typography
        variant="body2"
        onClick={handleClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          textDecoration: variant === 'primary' ? 'none' : 'underline',
          cursor: 'pointer',
          fontWeight: variant === 'primary' ? '600' : '400',
          color: disabled
            ? 'button.text.disabled'
            : color
              ? color
              : variant === 'primary'
                ? 'text.link'
                : 'button.text.text',
          '&:hover': {
            textDecoration: disabled ? 'underline' : 'none',
            color: disabled ? 'button.text.disabled' : 'button.text.hover',
          },
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};
