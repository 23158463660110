import { ListItemButton, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface IProps {
  Icon: React.ElementType;
  isActive?: boolean;
  disabled?: boolean;
  label: string;
  href?: string;
  onClick?: () => void;
}
export const LinkDrawer: React.FC<IProps> = ({
  Icon,
  isActive,
  disabled,
  label,
  href,
  onClick,
}) => {
  const navigate = useNavigate();

  return (
    <ListItemButton
      disabled={disabled}
      autoFocus={isActive}
      onClick={() => {
        onClick && onClick();
        href && navigate(href);
      }}
      sx={{
        backgroundColor: isActive ? 'button.secondary.frontActive' : '',
        color: isActive ? 'brand.accent' : 'text.primary',
        borderRadius: '4px',
      }}
    >
      {Icon && <Icon color={isActive ? '' : 'action'} />}
      <ListItemText primary={label} sx={{ marginLeft: '1.25rem' }} />
    </ListItemButton>
  );
};
