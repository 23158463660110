import { ReactNode, useMemo } from 'react';

import { Typography } from '@mui/material';
import { RowsDataLayout } from 'components/common';

import { ICandidateDetails } from '@types';

export const MatchingPositionProjectList: React.FC<{
  data: ICandidateDetails;
}> = ({ data }) => {
  const mainProfileRowsData = useMemo(() => {
    const res: { value: string | ReactNode }[] = [];

    data.positions?.map((item) => {
      res.push({
        value: (
          <Typography variant="body1" color="text.primary">
            {item.name}
          </Typography>
        ),
      });
    });

    return res;
  }, [data]);

  return <RowsDataLayout data={mainProfileRowsData} />;
};
