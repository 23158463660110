import axios from 'axios';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { authStoreKey } from './auth.const';
import { setSnackbar } from '@redux/snackbar';

import {
  SnackbarType,
  forgotPasswordAPI,
  loginUserAPI,
  refreshTokenAPI,
  resetPasswordAPI,
  userProfileAPI,
} from '@constants';
import { ILoginResponse, IProfile } from '@types';
import { setAccessToken, setRefreshToken } from '@utils';

export const loginUser = createAsyncThunk(
  `${authStoreKey}/loginUser`,
  async ({ username, password }: { username: string; password: string }) => {
    try {
      const response = await axios.post<ILoginResponse>(loginUserAPI, {
        username,
        password,
      });

      setAccessToken(response.data.access_token);
      setRefreshToken(response.data.refresh_token);

      return response.data.profile;
    } catch (e: any) {
      throw e;
    }
  },
);

export const logoutUser = createAsyncThunk(
  `${authStoreKey}/logoutUser`,
  async () => {
    setAccessToken(null);
    setRefreshToken(null);
  },
);

export const forgotUserPassword = createAsyncThunk(
  `${authStoreKey}/forgotUserPassword`,
  async (username: string, thunkAPI) => {
    try {
      const response = await axios.post(forgotPasswordAPI, { username });

      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Email to ${username} has been sent`,
        }),
      );

      return response.data;
    } catch (e: any) {
      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: e?.response?.data?.message || 'Something went wrong',
        }),
      );

      throw e;
    }
  },
);

export const resetUserPassword = createAsyncThunk(
  `${authStoreKey}/resetUserPassword`,
  async (
    { password, token }: { password: string; token: string },
    thunkAPI,
  ) => {
    try {
      const response = await axios.post<ILoginResponse>(resetPasswordAPI, {
        password,
        token,
      });

      setAccessToken(response.data.access_token);
      setRefreshToken(response.data.refresh_token);

      return response.data.profile;
    } catch (e: any) {
      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: e?.response?.data?.message || 'Something went wrong',
        }),
      );

      throw e;
    }
  },
);

export const refreshUserToken = createAsyncThunk(
  `${authStoreKey}/refreshUserToken`,
  async () => {
    const response = await axios.post<string>(refreshTokenAPI, null, {
      withCredentials: true,
    });

    setAccessToken(response.data);

    return response.data;
  },
);

export const fetchProfile = createAsyncThunk(
  `${authStoreKey}/fetchProfile`,
  async () => {
    const response = await axios.get<IProfile>(userProfileAPI);

    return response.data;
  },
);
