import { ReactNode } from 'react';

import { Box } from '@mui/material';
import { NavDrawer, PageWrapper, WelcomeScreen } from '@components';
import { useWelcomeScreen } from '@hooks';

export const AppLayout = ({ children }: { children: ReactNode }) => {
  const showWelcomeScreen = useWelcomeScreen();

  if (showWelcomeScreen) {
    return (
      <PageWrapper>
        <WelcomeScreen />
      </PageWrapper>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
      }}
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
    >
      <NavDrawer />
      <Box flex={1} mt={{ xs: '64px', md: '0' }}>
        {children}
      </Box>
    </Box>
  );
};
