import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ApiStatus } from '@constants';
import { positionsStoreKey } from './positions.const';

import { IPositionsSliceState } from './positions.types';
import { fetchPositions } from './positions.thunk';

const initialState: IPositionsSliceState = {
  apiStatus: ApiStatus.IDLE,
  data: [],
};

const posiitonsSlice = createSlice({
  name: positionsStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPositions.pending, (state) => {
        state.apiStatus = ApiStatus.LOADING;
      })
      .addCase(fetchPositions.fulfilled, (state, action) => {
        state.apiStatus = ApiStatus.COMPLETE;
        state.data = action.payload;
      })
      .addCase(fetchPositions.rejected, (state, action: PayloadAction<any>) => {
        state.apiStatus =
          action?.payload?.statusCode === 403
            ? ApiStatus.FORBIDDEN
            : ApiStatus.FAILED;
      });
  },
});

export const positionsSliceReducer = posiitonsSlice.reducer;
