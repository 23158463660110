import React from 'react';
import {
  Box,
  CircularProgress,
  Stack,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { BottomGradientBar, BrandLogo } from 'components/common';

interface ILoaderProps {
  showBrandLogo?: boolean;
}

export const Loader: React.FC<ILoaderProps> = ({ showBrandLogo }) => {
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      position={'absolute'}
      width={'100%'}
      height={'100%'}
      data-testid="is-loading"
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          backgroundColor: theme.palette.common.white,
        },
      })}
    >
      {showBrandLogo && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            m: {
              xs: '1rem',
              md: '2rem',
            },
          }}
        >
          <BrandLogo />
        </Box>
      )}
      <CircularProgress sx={{ color: (theme) => theme.palette.brand.accent }} />
      {!hidden ? <BottomGradientBar position={'absolute'} bottom={0} /> : null}
    </Stack>
  );
};
