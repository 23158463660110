import { useEffect } from 'react';

import { authSelectors, setShowWelcomeScreen } from '@redux/auth';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

export const useWelcomeScreen = () => {
  const dispatch = useAppDispatch();
  const showWelcomeScreen = useAppSelector(authSelectors.getShowWelcomeScreen);

  useEffect(() => {
    let timer1: NodeJS.Timeout;
    if (showWelcomeScreen) {
      timer1 = setTimeout(() => {
        dispatch(setShowWelcomeScreen(false));
      }, 3000);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return showWelcomeScreen;
};
